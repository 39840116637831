// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-produkte-gefuellte-okarabaellchen-mdx": () => import("./../../../src/pages/produkte/gefuellte_Okarabaellchen.mdx" /* webpackChunkName: "component---src-pages-produkte-gefuellte-okarabaellchen-mdx" */),
  "component---src-pages-produkte-js": () => import("./../../../src/pages/produkte.js" /* webpackChunkName: "component---src-pages-produkte-js" */),
  "component---src-pages-produkte-mdx-slug-js": () => import("./../../../src/pages/produkte/{mdx.slug}.js" /* webpackChunkName: "component---src-pages-produkte-mdx-slug-js" */),
  "component---src-pages-produkte-okara-mdx": () => import("./../../../src/pages/produkte/okara.mdx" /* webpackChunkName: "component---src-pages-produkte-okara-mdx" */),
  "component---src-pages-produkte-okaraballchen-mdx": () => import("./../../../src/pages/produkte/Okaraballchen.mdx" /* webpackChunkName: "component---src-pages-produkte-okaraballchen-mdx" */),
  "component---src-pages-produkte-pfannenfertiger-tofu-mdx": () => import("./../../../src/pages/produkte/pfannenfertigerTofu.mdx" /* webpackChunkName: "component---src-pages-produkte-pfannenfertiger-tofu-mdx" */),
  "component---src-pages-produkte-tofu-eingelegt-mdx": () => import("./../../../src/pages/produkte/tofu_eingelegt.mdx" /* webpackChunkName: "component---src-pages-produkte-tofu-eingelegt-mdx" */),
  "component---src-pages-produkte-tofu-mdx": () => import("./../../../src/pages/produkte/tofu.mdx" /* webpackChunkName: "component---src-pages-produkte-tofu-mdx" */),
  "component---src-pages-produkte-workshop-mdx": () => import("./../../../src/pages/produkte/workshop.mdx" /* webpackChunkName: "component---src-pages-produkte-workshop-mdx" */),
  "component---src-pages-rezepte-js": () => import("./../../../src/pages/rezepte.js" /* webpackChunkName: "component---src-pages-rezepte-js" */),
  "component---src-pages-rezepte-mapodoufu-mdx": () => import("./../../../src/pages/rezepte/mapodoufu.mdx" /* webpackChunkName: "component---src-pages-rezepte-mapodoufu-mdx" */),
  "component---src-pages-rezepte-mdx-slug-js": () => import("./../../../src/pages/rezepte/{mdx.slug}.js" /* webpackChunkName: "component---src-pages-rezepte-mdx-slug-js" */),
  "component---src-pages-rezepte-okaraballchen-mdx": () => import("./../../../src/pages/rezepte/okaraballchen.mdx" /* webpackChunkName: "component---src-pages-rezepte-okaraballchen-mdx" */),
  "component---src-pages-u-cateing-mdx": () => import("./../../../src/pages/u/cateing.mdx" /* webpackChunkName: "component---src-pages-u-cateing-mdx" */),
  "component---src-pages-u-lieferservice-mdx": () => import("./../../../src/pages/u/lieferservice.mdx" /* webpackChunkName: "component---src-pages-u-lieferservice-mdx" */),
  "component---src-pages-u-mittag-mdx": () => import("./../../../src/pages/u/Mittag.mdx" /* webpackChunkName: "component---src-pages-u-mittag-mdx" */),
  "component---src-pages-u-paladar-limeriki-mdx": () => import("./../../../src/pages/u/paladar_limeriki.mdx" /* webpackChunkName: "component---src-pages-u-paladar-limeriki-mdx" */),
  "component---src-pages-u-werksverkauf-mdx": () => import("./../../../src/pages/u/werksverkauf.mdx" /* webpackChunkName: "component---src-pages-u-werksverkauf-mdx" */),
  "component---src-pages-u-workshops-mdx": () => import("./../../../src/pages/u/workshops.mdx" /* webpackChunkName: "component---src-pages-u-workshops-mdx" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-pages-was-wir-produzieren-js": () => import("./../../../src/pages/was-wir-produzieren.js" /* webpackChunkName: "component---src-pages-was-wir-produzieren-js" */),
  "component---src-pages-wer-wir-sind-js": () => import("./../../../src/pages/wer-wir-sind.js" /* webpackChunkName: "component---src-pages-wer-wir-sind-js" */)
}

